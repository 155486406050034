import React from 'react';
import { Container, ContainerFlex, Expand, Card, Button, Text } from './Containers';
import Icon from '@mdi/react';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';

export const TableMode = {
    incidences: "MODE_INCIDENCES",
}

export const Table = (props) => {

    const { title, columns, colorHeader = "colorAccent", generateChildren } = props;
    const { pagination = false, prevPage, nextPage, numResults = 0, currentPage = 0, } = props;
    const { items = [] } = props;

    return (
        <Card direction="column" color="colorWhite">
            <Card radius="0px" radiustl="6px" radiustr="6px" width="12" color={colorHeader} px="16">
                {
                    title ?
                        <Text color="colorWhite" size="13" children={title} />
                        : null
                }
                {
                    columns ?
                        <ContainerFlex width="12" pt={title ? "16" : ""}>
                            {columns}
                        </ContainerFlex>
                        : null
                }
            </Card>

            <Container width="12">
                {generateChildren(currentPage, numResults)}
            </Container>
            {
                pagination ?
                    <ContainerFlex width="12" px="25" justify="flex-end" align="center">
                        <Text
                            color="textSecondary"
                            size="10"
                            children={`Resultados por página ${numResults}`} />
                        <ContainerFlex ml="16" mr="16">
                            <Text
                                color="textSecondary"
                                size="10"
                                children={`${currentPage} - ${numResults * currentPage} de ${items.length}`} />
                        </ContainerFlex>

                        <Button maxheight="24px" onclick={prevPage} mr="4" shadow={false}>
                            <Icon path={mdiChevronLeft} color="#808080" size="24px" />
                        </Button>

                        <Button maxheight="24px" onclick={nextPage} mr="4" shadow={false}>
                            <Icon path={mdiChevronRight} color="#808080" size="24px" />
                        </Button>
                    </ContainerFlex>
                    : <Container px="16" width="12" />
            }
        </Card>
    );
};

export const HeaderTable = ({ columns }) =>
    columns.map((item, index) => <Expand key={index}><ContainerFlex width="12">
        <Text color="colorWhite" size="12" children={item} />
    </ContainerFlex></Expand>)

export const EmptyData = ({ msg = "No hay datos disponibles" }) => <ContainerFlex px="16" justify="center" width="12" children={<Text color="colorPrimary" size="14" children={msg} />} />

export const RowTable = ({ data, action }) => {
    var columns = data.map((item, index) => <Expand key={index}><ContainerFlex pv="8" width="12">
        <Text color="colorPrimary" size="14" children={item} />
    </ContainerFlex></Expand>);

    if (action) {
        columns.push(
            <Expand><ContainerFlex pv="8" width="12">
                {action}
            </ContainerFlex></Expand>);
    }

    return columns;
}

export const FieldTable = ({ data }) =>
    data.map((item, index) =>
        <ContainerFlex
            color={index % 2 === 0 ? "colorWhite" : "colorBackground"}
            key={index}
            width="12"
            pt={index === 0 ? '16' : '8'} pb="8" ph="16">
            <Text color="colorAccent" weight="bold" size="14" children={`${item.key}:`} />
            <Text pl="16" color="colorAccent" size="13" children={item.value} />
        </ContainerFlex>)