import React, { Fragment, useContext } from 'react';
import { UserContext } from '../provider/UserProvider';
import LoginView from './LoginView';
import MainView from './main/MainView';

const SwitchView = () => {
    const { state } = useContext(UserContext);
    return (
        <Fragment>
            {
                state.login
                    ? <MainView />
                    : <LoginView />
            }
        </Fragment>
    );
};

export default SwitchView;