import { UserAction } from "../provider/UserProvider"

export const HttpOperations = {
    login: "/dashboard/login",
    incidences: "/dashboard/incidences",
    message: "/dashboard/messages",

    util: "/dashboard/util",
    image: "/dashboard/image"
}

export const HttpConnection = async (operation, success, error, method = "GET", data = null) => {

    const userJSON = localStorage.getItem(UserAction.save_user);
    const user = userJSON !== null ? JSON.parse(userJSON) : null

    const request = await fetch(`${serverUrlEndpoint()}${operation}`, {
        method: method,
        body: data === null ? null : JSON.stringify(data),
        headers: {
            'Authorization': `Bearer ${user !== null ? user.token : ''}`,
            'Content-Type': 'application/json',
        }
    })

    let item = Object()

    if (!request.ok) {
        error(request.status);
    } else {
        try {
            item = await request.json();
        } catch (error) {
            item = {};
        }
        success(item);
    }
}

export const HttpConnectionImage = async (success, error, file) => {

    const userJSON = localStorage.getItem(UserAction.save_user);
    const user = userJSON !== null ? JSON.parse(userJSON) : null

    const data = new FormData()
    data.append('file', file)

    const request = await fetch(`${serverUrlEndpoint()}${HttpOperations.image}`, {
        method: "POST",
        body: data,
        headers: {
            'Authorization': `Bearer ${user !== null ? user.token : ''}`,
        }
    })

    let item = Object()

    if (!request.ok) {
        error(request.status);
    } else {
        try {
            item = await request.json();
        } catch (error) {
            item = {};
        }
        success(item);
    }
}

const serverUrlEndpoint = () => false ? "http://localhost/hubiqus/recicla2-api/public/api"
        : "https://recicla2.dashboard-manager.es/recicla2-api/public/api";

export const imageUrlEndpoint = false ? "http://localhost/hubiqus/recicla2-api/public/images/"
    : "https://recicla2.dashboard-manager.es/recicla2-api/public/images/";