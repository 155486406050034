import React, { useState } from 'react';
import styled from 'styled-components'
import Icon from '@mdi/react';
import { mdiMenuDown } from '@mdi/js';

/* UTIL */
const widthResponsive = (attr) => {
    const { width, xs, sm, lg, xl } = attr;
    const mwidth = `${width ? `col-${width}` : ``}${xs ? ` col-xs-${xs}` : ``}${sm ? ` col-sm-${sm}` : ``}${lg ? ` col-lg-${lg}` : ``}${xl ? ` col-xl-${xl}` : ``}`;
    return mwidth;
}

const marginAndPadding = (props) => {
    const { px = 0, pt, pr, pb, pl, ph, pv } = props;
    const { mx = 0, mt, mr, mb, ml, mh, mv } = props;
    const padding = `${pt ? pt : pv ? pv : px}px ${pr ? pr : ph ? ph : px}px ${pb ? pb : pv ? pv : px}px ${pl ? pl : ph ? ph : px}px`;
    const margin = `${mt ? mt : mv ? mv : mx}px ${mr ? mr : mh ? mh : mx}px ${mb ? mb : mv ? mv : mx}px ${ml ? ml : mh ? mh : mx}px`;

    return `
        padding: ${padding};
        margin: ${margin};
    `;
}

/* COMPONENT CONTAINER */
const FmuiContainer = styled.div`
    ${props => {
        //Set default values
        const { position = 'initial', top, rigth, bottom, left } = props.attr;
        const { height, minwidth, maxwidth, minheight, maxheight, overflowY = "initial" } = props.attr;
        const { color } = props.attr;

        return `
        position: ${position};
        display: block;
        ${top ? `top: ${top};` : ``}
        ${rigth ? `rigth: ${rigth};` : ``}
        ${bottom ? `bottom: ${bottom};` : ``}
        ${left ? `left: ${left};` : ``}

        ${minwidth ? `min-width: ${minwidth};` : ``}
        ${minheight ? `min-height: ${minheight};` : ``}
        ${maxwidth ? `max-width: ${maxwidth};` : ``}
        ${maxheight ? `max-height: ${maxheight};` : ``}
        ${height ? `height: ${height};` : ``}


        ${marginAndPadding(props.attr)}
        background-color: var(--${color});
        box-sizing: border-box;
        overflow-y: ${overflowY};
    `;
    }}
`;

export const Container = (attr) => {
    const { extras, children } = attr;
    const mwidth = widthResponsive(attr);
    return (
        <FmuiContainer
            attr={attr}
            className={`${mwidth}${extras ? ` ${extras}` : ""}`}
            children={children} />
    );
}

/* COMPONENT CONTAINER-FLEX */
const FmuiContainerFlex = styled(FmuiContainer)`
    ${props => {
        //Set defaults values
        const { multiline = true } = props.attr;
        const {
            direction = "row",
            justify = "flex-start",
            align = "flex-start",
            alignContent = "auto" } = props.attr;

        return `
        display: flex;
        flex-wrap: ${multiline ? "wrap" : "nowrap"};
        flex-direction: ${direction};
        justify-content: ${justify};
        align-items: ${align};
        align-content: ${alignContent};
    `;
    }}
`;

export const ContainerFlex = (attr) => {
    const { extras, children } = attr;
    const mwidth = widthResponsive(attr);
    return (
        <FmuiContainerFlex
            attr={attr}
            className={`${mwidth}${extras ? ` ${extras}` : ""}`}
            children={children} />
    );
}

/* COMPONENT CARD */
const FmuiCard = styled(FmuiContainer)`
    ${props => cardProps(props)}
`;

const FmuiCardFlex = styled(FmuiContainerFlex)`
    ${props => cardProps(props)}
`;

const cardProps = (props) => {
    const { radius = "6px", radiustl, radiustr, radiusbr, radiusbl } = props.attr;
    const { border = "none", borderWidth, borderColor } = props.attr;
    const { shadow = true } = props.attr;

    return `
            ${border !== 'none' ? `border: ${borderWidth} ${border} var(--${borderColor})` : ''};
            border-top-left-radius: ${ radiustl ? radiustl : radius};
            border-top-right-radius: ${ radiustr ? radiustr : radius};
            border-bottom-right-radius: ${ radiusbr ? radiusbr : radius};
            border-bottom-left-radius: ${ radiusbl ? radiusbl : radius};
            ${ shadow ? `box-shadow: 0 0 0 1px rgba(205,210,223,0.15), 0 6px 12px 0 rgba(205,210,223,0.3);` : ``}
        `;

}

export const Card = (attr) => {
    const { children, flex = false } = attr;
    const mwidth = widthResponsive(attr);
    return flex
        ? <FmuiCardFlex attr={attr} className={mwidth} children={children} />
        : <FmuiCard attr={attr} className={mwidth} children={children} />;
}

/* COMPONENT BUTTON */
const FmuiButton = styled(FmuiCard)`
    ${props => buttonProps(props)}
`;

const FmuiButtonFlex = styled(FmuiCardFlex)`
    ${props => buttonProps(props)}
`;

const buttonProps = (attr) => `
    cursor: pointer;
    overflow-y:hidden;
    & > label {
        cursor: pointer;
    }
`;

export const Button = (attr) => {
    const { onclick, children, flex = false } = attr;
    const mwidth = widthResponsive(attr);
    return (
        flex
            ? <FmuiButtonFlex attr={attr} onClick={onclick} className={`ripple ${mwidth}`} children={children} />
            : <FmuiButton attr={attr} onClick={onclick} className={`ripple ${mwidth}`} children={children} />
    );
}

/* COMPONENT EXPAND */
const FmuiExpand = styled(FmuiContainer)`
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
`;

export const Expand = (attr) => {
    const { children } = attr;
    return <FmuiExpand attr={attr} children={children} />;
};

/* COMPONENT TEXT */
const FmuiLabel = styled.label`
    ${props => {
        const {
            size = 16,
            weight = "normal",
            color = "textPrimary",
            cursor = "text",
            align = "left"
        } = props.attr;

        return `
            font-size: ${size}px;
            font-weight: ${weight};
            color: var(--${color});
            cursor: ${cursor};
            text-align: ${align}
            ${marginAndPadding(props.attr)}
        `;
    }}
`;

export const Text = (attr) => {
    return (
        <FmuiLabel attr={attr}>
            {attr.children}
        </FmuiLabel>
    );
};

/* COMPONENT IMAGE */
const FmuiImage = styled.img`
    ${props => {
        return `
            ${marginAndPadding(props.attr)}
        `;
    }}
`;

export const Image = (attr) => {
    const { src } = attr;
    const mwidth = widthResponsive(attr);

    return <FmuiImage src={src} attr={attr} className={mwidth} />
};

/* COMPONENT INPUT */
export const Input = styled.input`
    ${props => {
        const { color = "colorBlack", holderColor = "textPrimary", size = "16", weight = "normal" } = props;
        return `
            color: var(--${color});
            font-size: ${size}px;
            font-weight: ${weight};
            border: none;
            background-color: transparent;
            width: 100%;
            margin: 0px;

            &::placeholder {
                color: var(--${holderColor});
            }

            &:focus{
                outline: none;
                width: 100%;
            }
        `;
    }}
`;

export const TextArea = styled.textarea`
    ${props => {
        const { color = "colorBlack", holderColor = "textPrimary", size = "16", weight = "normal" } = props;
        return `
            color: var(--${color});
            font-size: ${size}px;
            font-weight: ${weight};
            border: none;
            background-color: transparent;
            width: 100%;
            margin: 0px;
            resize:none;

            &::placeholder {
                color: var(--${holderColor});
            }

            &:focus{
                outline: none;
                width: 100%;
            }
        `;
    }}
`;

/* COMPONENT DROPDOWN MENY */
const FmuiSelector = styled(FmuiContainerFlex)`
    ${ props => {
        const { justify = "left" } = props.attr
        return `
        position: relative;
        display: inline-block;
        cursor: pointer;
    
        & .popupcontainer {
            -ms-flex-preferred-size: 0;
            width: calc(100% - 32px);
            text-align: center;
            border-radius: 3px;
            padding: 8px 16px;
            position: absolute;
            z-index: 1;
            top: 100%;
            ${justify}: 16px;
            box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15);
            max-height: 220px;
            overflow-y:auto;
        }
        `;
    }}
`;

export const DropDownMenu = (attr) => {
    const [show, setShow] = useState(false);
    const { children } = attr;
    const mwidth = widthResponsive(attr);

    const _handleOpen = (e) => {
        e.stopPropagation();
        if (!show) document.addEventListener('click', _handleClose)
        setShow(!show)
    }

    const _handleClose = (e) => {
        console.log(e.target.id)
        if (e.target.id !== "item-1") {
            document.removeEventListener('click', _handleClose)
            setShow(false)
        }
    }

    return (
        <FmuiSelector id="menu" className={mwidth} attr={attr} onClick={_handleOpen}>
            {children[0]}
            {show ? children[1] : null}
        </FmuiSelector>
    )
}

/* INPUT COMPONENT FORM */
export const InputForm = ({ name, placeholder, value, handleChange, width = "6" }) => {
    return (
        <Container
            shadow={false} width={width}>
            <Card
                mh="16" mv="16" ph="16" pv="16"
                shadow={false}
                border="solid" borderWidth="1px" borderColor="colorPrimary"
                color="colorWhite">
                <Input
                    key={[name, 'FieldInput'].join('_')}
                    onChange={handleChange}
                    name={name}
                    value={value === null ? '' : value}
                    placeholder={placeholder}
                    color="colorPrimary"
                    holderColor="colorPrimary" />
            </Card>
        </Container>
    );
}

export const AreaForm = ({ name, placeholder, value, handleChange, width = "6" }) => {
    return (
        <Container
            shadow={false} width={width}>
            <Card
                mh="16" mv="16" ph="16" pv="16"
                shadow={false}
                border="solid" borderWidth="1px" borderColor="colorPrimary"
                color="colorWhite">
                <TextArea
                    key={[name, 'FieldArea'].join('_')}
                    onChange={handleChange}
                    name={name}
                    value={value === null ? '' : value}
                    placeholder={placeholder}
                    color="colorPrimary"
                    rows="10"
                    reizable="none"
                    holderColor="colorPrimary" />
            </Card>
        </Container>
    );
}

export const InputSelector = ({ name, placeholder, value, values, handleSelect, width = "6", shadow = false }) => {
    return (
        <DropDownMenu width={width} overflowY="inherit">
            <Container>
                <Card
                    mh="16" mv="16" ph="16" pv="16"
                    flex={true} align="center"
                    border="solid" borderWidth="1px" borderColor="colorPrimary"
                    shadow={shadow}
                    color="colorWhite">
                    <Expand>
                        <Text
                            color="colorBlack"
                            children={value === '' ? placeholder : value} />
                    </Expand>
                    <Icon path={mdiMenuDown} color="#808080" size="24px" />
                </Card>
            </Container>
            <Container color="colorWhite" extras="popupcontainer" mt="-18">
                {
                    values != null ?
                        values.map((item) =>
                            <Button key={item.id} onclick={() => handleSelect(item, name)} px="8" shadow={false}>
                                <Text color="colorBlack" children={`${item.name}`} />
                            </Button>
                        )
                        : null
                }
            </Container>
        </DropDownMenu>
    );
}

/** MODAL COMPONENT */
export const Modal = ({ show, setShow, children }) => {

    const FmuiModal = styled.div`
        display: ${show ? 'auto' : 'none'} ; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 1; /* Sit on top */
        left: 0;
        top: 0;
        width: 100vw; /* Full width */
        height: 100vh; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    `;

    return (
        <FmuiModal onClick={() => setShow(false)}>
            {children}
        </FmuiModal>
    );
}