import React, { useReducer } from 'react';

const UserContext = React.createContext();
const UserConsumer = UserContext.Consumer;

export const UserAction = {
    login: "LOGIN",
    logout: "LOGOUT",
    save_user: "save_user",
}

const reduce = (state, action) => {
    switch (action.type) {
        case UserAction.login:
            localStorage.setItem(UserAction.save_user, JSON.stringify(action.payload));
            return { ...state, login: true };
        case UserAction.logout:
            localStorage.removeItem(UserAction.save_user);
            return { ...state, login: false };
        case UserAction.showDrawer:
            return { ...state, drawer: true };
        case UserAction.hiddeDrawer:
            return { ...state, drawer: false };
        default:
            return { ...state, login: false };
    }
}

const initialState = {
    login: localStorage.getItem(UserAction.save_user) !== null,
    drawer: false
}

const UserProvider = (props) => {
    const [state, dispatch] = useReducer(reduce, initialState);
    let value = { state, dispatch };

    return (
        <UserContext.Provider value={value}>
            {props.children}
        </UserContext.Provider>
    );
}

export { UserContext, UserConsumer, UserProvider };