import React, { useState, useEffect, useRef } from 'react';
import { Container, Text, ContainerFlex, Button, Card, InputSelector, Expand, InputForm, AreaForm, Image, Modal } from '../../util/Containers';
import { TableMode, Table, HeaderTable, RowTable, EmptyData } from '../../util/Table';
import { HttpConnection, HttpOperations, HttpConnectionImage, imageUrlEndpoint } from '../../net/HttpConnection';

const filterDefault = {
    state: null,
    place: null,
    startDate: null,
    endDate: null
}

const IncidenceList = ({ setSelected, states, places }) => {

    const [incidences, setIncidences] = useState([]);
    const [filter, setFilter] = useState(filterDefault)

    const generateChildren = (mode, items) => {
        return items.length > 0 ? items.map((item, index) =>
            <Button
                onclick={() => setSelected(item)}
                color={index % 2 === 0 ? "colorWhite" : "colorBackground"}
                shadow={false}
                flex="true"
                pv="8"
                ph="16"
                key={index}
                children={
                    <RowTable data={[item.id, item.title,
                    item.place.name,
                    item.last_message[0].status.name,
                    item.create_at,
                    item.last_message[0].create_at,
                    item.user.name]} />
                } />)
            : <EmptyData />
    }

    useEffect(() => {
        const requestAPI = () => {
            HttpConnection(HttpOperations.incidences,
                (response) => {
                    setIncidences(response.item)
                },
                (error) => {
                    console.log(error)
                }, "POST", filter)
        }
        requestAPI();
    }, [filter])

    const _handleSelect = (selectItem, name) => {
        setFilter({
            ...filter,
            [name]: selectItem
        })
    }
    const _handleChange = (e) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        })
    }

    return (
        <Container>
            <ContainerFlex color="colorPrimary" justify="center" pt="60" pb="60">
                <Text size="60" color="colorWhite" children="Incidencias" />
            </ContainerFlex>
            <Container mt="-25" ph="32" pb="32">

                <Card color="colorWhite">
                    <Card color="colorAccent" px="16" shadow={false} radiusbr="0" radiusbl="0">
                        <Text weight="bold" color="colorWhite" children="Filtros disponibles" />
                    </Card>
                    <ContainerFlex justify="space-between" align="center" px="8">

                        <ContainerFlex width="12" sm="6" align="center" justify="between">

                            <InputForm
                                name="startDate"
                                value={filter.startDate}
                                handleChange={_handleChange}
                                placeholder="Fecha ini. (dd/mm/yyyy)" />
                            <InputForm
                                name="endDate"
                                value={filter.endDate}
                                handleChange={_handleChange}
                                placeholder="Fecha fin (dd/mm/yyyy)" />

                        </ContainerFlex>

                        <ContainerFlex width="12" sm="6" align="center" justify="between">
                            <InputSelector
                                name="place"
                                placeholder="Municipio"
                                value={filter.place != null ? filter.place.name : ''}
                                values={places}
                                handleSelect={_handleSelect} />

                            <InputSelector
                                name="state"
                                placeholder="Estado"
                                value={filter.state != null ? filter.state.name : ''}
                                values={states}
                                handleSelect={_handleSelect} />
                        </ContainerFlex>

                    </ContainerFlex>
                    <ContainerFlex px="8" justify="center">
                        <Button
                            mx="8"
                            onclick={() => {
                                setFilter(filterDefault)
                            }}
                            ph="16" pv="8" color="colorPrimary"
                            children={<Text color="colorWhite" weight="bold" size="13" children="LIMPIAR" />} />
                    </ContainerFlex>
                </Card>
            </Container>
            <Container ph="32" pb="32">
                <Table
                    columns={<HeaderTable columns={['ID', 'TÍTULO', 'TIPO', 'ESTADO', 'FECHA ALTA', 'FECHA MODIFICACIÓN', 'USUARIO']} />}
                    generateChildren={() => generateChildren(TableMode.incidences, incidences)} />
            </Container>
        </Container>
    );
};

const messageDefault = {
    title: null,
    text: null,
    image_url: null,
    state: null,
    incidence_id: null
}

const infoDefault = {
    content: null,
    color: null,
}

const IncidenceDetail = ({ selected, setSelected, states }) => {
    const [item, setItem] = useState(selected);
    const [message, setMessage] = useState(messageDefault);
    const [showImage, setShowImage] = useState(false);
    const [image, setImage] = useState();

    //Image
    const [imagePreview, setImagePreview] = useState();
    const refInputFile = useRef(null);

    const [info, setInfo] = useState(infoDefault)

    const requestAPI = () => {
        HttpConnection(`${HttpOperations.incidences}/${item.id}`,
            (response) => {
                setItem(response.item)
            },
            (error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        //Asignamos el id de la incidencia al mensaje
        setMessage({
            ...message,
            incidence_id: item.id
        })

        requestAPI();
    }, [item.id])

    const _handleImageChange = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            setImagePreview({
                file: file,
                url: reader.result
            });
        }

        if (file !== null)
            reader.readAsDataURL(file)
    }

    const validateMessage = () => {
        if (message.title === null || message.text === null || message.state == null) {
            setInfo({
                content: "Todos los campos son obligatorios",
                color: "colorRedDark"
            })
        } else {
            setInfo({
                content: "Enviando mensaje por favor espere...",
                color: "colorBlue"
            })

            if (imagePreview != null && imagePreview.file !== null) {
                sendImage();
            } else {
                sendMessage();
            }
        }
    }

    //Enviar imagen al servidor
    const sendImage = () => {
        HttpConnectionImage((response) => {
            sendMessage(response.item);
        },
            (error) => {
                setInfo({
                    content: "Error al enviar la imagen",
                    color: "colorRedDark"
                })
            }, imagePreview.file)
    }

    //Enviar mensaje al sevidor
    const sendMessage = (image = null) => {

        var myMessage = message;
        myMessage.image_url = image;

        HttpConnection(`${HttpOperations.message}`,
            (response) => {
                setInfo({
                    content: "Mensaje enviado correctamente",
                    color: "colorAccent"
                });

                setMessage({
                    ...messageDefault,
                    incidence_id: item.id
                });
                requestAPI();
            },
            (error) => {
                setInfo({
                    content: "Error al enviar el mensaje",
                    color: "colorRedDark"
                })
            }, "POST", myMessage)
    }

    const RowMessage = ({ row, handleModalImage }) =>
        <ContainerFlex align="center">
            <Card
                px="16"
                mh="32"
                radius="16px"
                color={row.status.id === 3 ? "colorGreen" : row.status.id === 2 ? "colorYellow" : "colorRedDark"} />

            <Expand px="8">
                <Card px="8" flex={true} direction="column">
                    <Text mx="8" weight="bold" color="colorPrimary" children={row.title} />
                    <Text mx="8" color="colorPrimary" children={row.text} />
                    {
                        row.image_url ?
                            <ContainerFlex width="12" px="8" justify="center">
                                <Button
                                    onclick={() => handleModalImage(row.image_url)}
                                    ph="16" pv="8" color="colorPrimary"
                                    children={<Text color="colorWhite" weight="bold" size="13" children="VER IMAGEN" />} />
                            </ContainerFlex>

                            : null
                    }
                </Card>
            </Expand>
        </ContainerFlex>
        ;


    const _handleSelect = (selectItem, name) => {
        setMessage({
            ...message,
            [name]: selectItem
        })
    }

    const _handleChange = (e) => {
        setMessage({
            ...message,
            [e.target.name]: e.target.value
        })
    }

    const _handleModalImage = (url) => {
        setImage(url);
        setShowImage(true);
    }

    return (
        <Container>

            <Container>
                <ContainerFlex color="colorPrimary" justify="space-between" align="center" ph="32">
                    <Text size="60" mv="50" children="Estado de la incidencia" color="colorWhite" />
                    <Button
                        onclick={() => setSelected(null)}
                        mv="50" ph="16" pv="8" color="colorAccent" children={<Text color="colorWhite" weight="bold" size="13" children="VOLVER" />} />
                </ContainerFlex>
                <ContainerFlex mt="-33" px="8" align="stretch">

                    <Container width="12" sm="6" px="8">
                        <Card pb="16">
                            <Card flex={true} justify="center" color="colorAccent" px="16" shadow={false} radiusbr="0" radiusbl="0">
                                <Text size="22" weight="bold" color="colorWhite" children="Información" />
                            </Card>
                            <ContainerFlex direction="column" ph="8" pv="16" color="colorBackground">
                                <Text size="20" weight="bold" color="colorPrimary" children="Título" />
                                <Text mt="8" color="colorPrimary" children={item.title} />
                            </ContainerFlex>
                            <ContainerFlex direction="column" ph="8" pv="16">
                                <Text size="20" weight="bold" color="colorPrimary" children="Descripción" />
                                <Text mt="8" color="colorPrimary" children={item.text} />
                            </ContainerFlex>
                            <ContainerFlex direction="column" ph="8" pv="16" color="colorBackground">
                                <Text size="20" weight="bold" color="colorPrimary" children="Dirección" />
                                <Text mt="8" color="colorPrimary" children={item.address} />
                            </ContainerFlex>
                            <ContainerFlex direction="column" ph="8" pv="16">
                                <Text size="20" weight="bold" color="colorPrimary" children="Municipio" />
                                <Text mt="8" color="colorPrimary" children={item.place.name} />
                            </ContainerFlex>
                            {
                                item.image_url ?
                                    <ContainerFlex px="8" pt="24" justify="center">
                                        <Button
                                            onclick={() => _handleModalImage(item.image_url)}
                                            ph="16" pv="8" color="colorPrimary"
                                            children={<Text color="colorWhite" weight="bold" size="13" children="VER IMAGEN" />} />
                                    </ContainerFlex>
                                    : null
                            }
                        </Card>

                        <Card mv="16" pb="16">
                            <Card flex={true} justify="center" color="colorAccent" px="16" shadow={false} radiusbr="0" radiusbl="0">
                                <Text size="22" weight="bold" color="colorWhite" children="Nuevo mensaje" />
                            </Card>
                            <ContainerFlex direction="column" ph="8" pv="16" color="colorBackground">
                                <InputForm
                                    name="title"
                                    value={message.title}
                                    handleChange={_handleChange}
                                    width="12"
                                    placeholder="Título del mensaje" />
                            </ContainerFlex>
                            <ContainerFlex direction="column" ph="8" pv="16">
                                <AreaForm
                                    name="text"
                                    value={message.text}
                                    handleChange={_handleChange}
                                    width="12"
                                    placeholder="Descripción del mensaje" />
                            </ContainerFlex>
                            <ContainerFlex direction="column" ph="8" pv="16" color="colorBackground">
                                <InputSelector
                                    name="state"
                                    placeholder="Estado"
                                    width="12"
                                    value={message.state != null ? message.state.name : ''}
                                    values={states}
                                    handleSelect={_handleSelect}
                                />
                            </ContainerFlex>

                            {
                                info.content ?
                                    <ContainerFlex justify="center" px="8">
                                        <Card px="8" color={info.color}>
                                            <Text color="colorWhite" weight="bold" children={info.content} />
                                        </Card>
                                    </ContainerFlex>
                                    : null
                            }

                            <ContainerFlex px="8" pt="24" justify="center">
                                <input
                                    ref={refInputFile}
                                    type="file"
                                    onChange={_handleImageChange}
                                    hidden
                                />
                                <Button
                                    mx="8"
                                    onclick={() => refInputFile.current.click()}
                                    ph="16" pv="8" color="colorPrimary"
                                    children={<Text color="colorWhite" weight="bold" size="13" children="AÑADIR IMAGEN" />} />

                                <Button
                                    mx="8"
                                    onclick={() => validateMessage()}
                                    ph="16" pv="8" color="colorAccent"
                                    children={<Text color="colorWhite" weight="bold" size="13" children="ENVIAR MENSAJE" />} />
                            </ContainerFlex>
                        </Card>
                    </Container>

                    <Container width="12" sm="6" px="8" pb="24">
                        <Card maxheight="100%" height="100%">
                            <Card flex={true} justify="center" color="colorAccent" px="16" shadow={false} radiusbr="0" radiusbl="0">
                                <Text size="22" weight="bold" color="colorWhite" align="center" children="Mensajes" />
                            </Card>
                            <Container px="8" maxheight="100%" overflowY="auto">
                                {
                                    item.messages && item.messages.length > 0 ?
                                        item.messages.map((item, index) => <RowMessage key={index} row={item} handleModalImage={_handleModalImage} />)
                                        : <EmptyData />
                                }
                            </Container>
                        </Card>
                    </Container>

                </ContainerFlex>
            </Container>

            {
                showImage ?
                    <Modal show={showImage} setShow={setShowImage}>
                        <ContainerFlex minwidth="100vw" minheight="100%" px="32" justify="center" align="center">
                            <ContainerFlex align="center" justify="center" alignContent="center">
                                <Image width="6" height="100vh" src={`${imageUrlEndpoint}${image}`} />
                            </ContainerFlex>
                        </ContainerFlex>
                    </Modal>
                    : null
            }


        </Container>
    );
}

const IncidenceView = () => {
    const [selected, setSelected] = useState()

    const [states, setStates] = useState([])
    const [places, setPlaces] = useState([])

    useEffect(() => {
        const requestAPI = () => {
            HttpConnection(HttpOperations.util,
                (response) => {
                    setStates(response.item.states)
                    setPlaces(response.item.places)
                },
                (error) => {
                    console.log(error)
                })
        }
        requestAPI();
    }, [])

    return selected
        ? <IncidenceDetail selected={selected} setSelected={setSelected} states={states} />
        : <IncidenceList setSelected={setSelected} states={states} places={places} />;
}

export default IncidenceView;