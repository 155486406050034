import React, { useContext } from 'react';
import { Switch, Route } from "react-router-dom";
import { ContainerFlex, Container, Expand, Button, Text } from '../../util/Containers';
import { UserContext, UserAction } from '../../provider/UserProvider';
import logo from '../../../image/logo.png';

import Icon from '@mdi/react';
import { mdiLogoutVariant } from '@mdi/js';
import IncidenceView from './IncidenceView';

const Header = () => {
    const { dispatch } = useContext(UserContext);

    const _handleLogin = () => dispatch({ type: UserAction.logout });

    return (
        <ContainerFlex ph="16" color="colorPrimary" justify="space-between" align="center">
            <Container px="8">
                <img height="50" alt="logo" className="action-button" src={logo} />
            </Container>
            <Button px="8" shadow={false} onclick={_handleLogin}>
                <Icon path={mdiLogoutVariant} color="#fff" size="24px" />
            </Button>
        </ContainerFlex>
    );
}

const MainView = () => {
    return (
        <ContainerFlex height="100vh" maxheight="100vh">
            <Expand maxheight="100%" overflowY="auto">
                <Header />
                <Switch>
                    <Route exact={true} path="/" component={IncidenceView} />
                </Switch>
                <Container mx="32">
                    {
                        //<Text color="colorPrimary" size="10" children="©2020 HUBIQUS CREATIVE SOLUTIONS" />
                    }
                </Container>
            </Expand>
        </ContainerFlex>
    );
};

export default MainView;