import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { UserProvider } from './component/provider/UserProvider';
import SwitchView from './component/view/SwitchView';

function App() {
  return (
    <Router basename="/">
      <UserProvider>
        <SwitchView />
      </UserProvider>
    </Router>
  );
}

export default App;
