import React, { useContext, useState } from 'react';
import { ContainerFlex, Card, Button, Text, Input } from '../util/Containers';
import logo from '../../image/logo_black.png';
import { UserContext, UserAction } from '../provider/UserProvider';
import { HttpConnection, HttpOperations } from '../net/HttpConnection';

const loginDefault = { email: '', password: ''}

const LoginView = () => {
    const { dispatch } = useContext(UserContext);
    const [loginData, setLoginData] = useState(loginDefault);

    const [info, setInfo] = useState()

    const _handleLogin = () => {
        if (loginData.email !== '' && loginData.password !== '') {
            HttpConnection(HttpOperations.login,
                (response) => {
                    //Save user
                    if (response && response.item.role_id == 2) {
                        dispatch({ type: UserAction.login, payload: response.item});
                    } else {
                        setInfo("E-mail o password incorrectos")
                    }
                },
                (error) => {
                    setInfo("E-mail o password incorrectos")
                }, "POST", loginData)
        } else {
            setInfo("Todos los datos son obligatorios")
        }
    }

    const _handleChange = (e) => {
        setLoginData({
            ...loginData,
            [e.target.name]: e.target.value
        })
    }

    return (
        <ContainerFlex minwidth="100vw" height="100vh">
            <ContainerFlex direction="column" width="12" minheight="100%" color="colorPrimary" justify="center" align="center">
                <img width="250" src={logo} alt="boxsix-logo" />
                <Text size="20" color="colorAccent" weight="bold" children="MANAGER" />

                <ContainerFlex width="12" lg="6" minheight="100%" justify="center" align="center" alignContent="center" px="32">

                    <Card width="10" px="16" mv="16" color="colorWhite">
                        <Input
                            onChange={_handleChange}
                            name="email"
                            value={loginData.email}
                            type="text"
                            placeholder="E-mail" />
                    </Card>

                    <Card width="10" px="16" mv="16" color="colorWhite">
                        <Input
                            onChange={_handleChange}
                            name="password"
                            value={loginData.password}
                            type="password"
                            placeholder="Password" />
                    </Card>

                    {
                        info ?
                            <ContainerFlex justify="center" px="8">
                                <Card shadow={false} px="8" color="colorRedDark">
                                    <Text color="colorWhite" weight="bold" children={info} />
                                </Card>
                            </ContainerFlex>
                            : null
                    }

                    <Button onclick={_handleLogin} width="10" px="16" mv="16" color="colorAccent" flex="true" align="center" justify="center">
                        <Text weight="bold" color="colorWhite" children="Iniciar sesión" />
                    </Button>

                </ContainerFlex>
            </ContainerFlex>

        </ContainerFlex>
    );
};

export default LoginView;